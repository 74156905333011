/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable t
    // set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
            // All pages
            'common': {
                init: function () {
                    // JavaScript to be fired on all pages

                    //CTA Cards - Thirds
                    $('.cta-card-third-row').equalize({
                        equalize: 'outerHeight',
                        children: '.cta-card-third-content'
                    });

                    //Header Icons
                    $('.top-navigation .account-icon a').before('<img src="/wp-content/themes/arras/dist/images/icons/account-icon.png">');
                    $('.top-navigation .basket-icon a').before('<img src="/wp-content/themes/arras/dist/images/icons/basket-icon.png">');

                    //Flexible Slider Init
                    if ($(".slider-container").length) {

                        $(".slider-container").owlCarousel({
                            items: 1,
                            autoplay: true,
                            autoplayHoverPause: true,
                            dots: false,
                            loop: true,
                            mouseDrag: false,
                            nav: true,
                            navText: [
                                "<img class='owl-left-icon' src='/wp-content/themes/arras/dist/images/slider/left.svg'>",
                                "<img class='owl-right-icon' src='/wp-content/themes/arras/dist/images/slider/right.svg'>"
                            ],
                        });

                    }


                    if ($(".testimonial-background").length) {

                        $(".testimonial-background .row").owlCarousel({
                            autoHeight: true,
                            autoplay: true,
                            autoplayHoverPause: true,
                            dots: false,
                            items: 1,
                            loop: true,
                            mouseDrag: false,
                            nav: true,
                            navText: [
                                "<img class='owl-left-icon' src='/wp-content/themes/arras/dist/images/icons/circle-arrow-white.png'>",
                                "<img class='owl-right-icon' src='/wp-content/themes/arras/dist/images/icons/circle-arrow-white.png'>"
                            ],
                        });
                    }

                    //Header Search Dropdown
                    $('.header-search').on('click', function () {
                        $('.dropdown-search').slideToggle();
                        $('.search-form .search-field').focus();
                    });

                    //Mega Menu Timer Transition
                    var timer;

                    $(".mega-menu li").on("mouseover", function () {

                            closeSubmenu();
                            clearTimeout(timer);

                            var anchor = $(this);
                            openSubmenu(anchor);

                        }
                    ).on("mouseleave", function () {
                        timer = setTimeout(
                            closeSubmenu
                            , 1000);
                    });

                    function openSubmenu(anchor) {
                        $(anchor).find(".mega-menu-submenu").addClass("open");
                        $(anchor).css({
                            "border-bottom": "6px solid #A32020",
                        });
                    }

                    function closeSubmenu() {
                        $(".mega-menu-submenu").removeClass("open");
                        $(".mega-menu li").css({
                            "border-bottom": "0",
                        });
                    }

                    //End of Mega Menu Transition


                    //Mobile Menu - Toggle offscreen navigtation
                    $('.mobile-burger-icon').toggle(
                        function () {
                            $(this).addClass("mobile-icon-active");
                            $('.menu-mobile-navigation-container').css({
                                'left': '0',
                            });

                        }, function () {
                            $(this).removeClass("mobile-icon-active");
                            $('.menu-mobile-navigation-container').css({
                                'left': '-250px',
                            });
                        }
                    );

                    // Mobile Toggle submenu navigation
                    $('.mobile-show-submenu').on('click', function () {
                        $(this).next('.mobile-submenu-level').addClass('active-mobile-sub-menu');
                    });

                    $('.mobile-submenu-back').on('click', function () {
                        $(this).closest('.mobile-submenu-level').removeClass('active-mobile-sub-menu');
                    });

                    //Sidebar

                    var headerHeight = $('.header').outerHeight();
                    var breadcrumbHeight = $('.breadcrumbs-background').outerHeight();

                    var totalHeaderHeight = headerHeight + breadcrumbHeight;

                    $('.service-anchor-down').on('click', function (event) {
                        event.preventDefault();

                        $('html, body').animate({
                            scrollTop: $($.attr(this, 'href')).offset().top - totalHeaderHeight
                        }, 500);
                    });

                    //Second level Accordion
                    $('.more-content-link').on('click', function (e) {
                        e.preventDefault();
                        var link = $('span', this);
                        var icon = $('.show-more-button', this);
                        $(this).closest('.second-level-page-link-content').find('.more-content').slideToggle('slow', function () {
                            if ($(this).is(':visible')) {
                                link.text('Hide More');
                                icon.addClass('show-more-button-active ');
                            } else {
                                link.text('Read More');
                                icon.removeClass('show-more-button-active ');
                            }
                        });
                    });

                    //FAQs
                    $('.faq-more-button').on('click', function () {
                        var button = $(this);
                        $(this).closest('.faq-title').find('.faq-content').slideToggle('slow', function () {
                            if ($(this).is(':visible')) {
                                button.addClass('faq-active');
                            } else {
                                button.removeClass('faq-active');
                            }
                        });
                    });

                    //Related content dropdown

                    $('.related-products-title .related-more-button').on('click', function () {
                        $(this).toggleClass('related-more-button-active');
                        $('.show-related-products').slideToggle();

                    });

                    $('.related-blog-title .related-more-button').on('click', function () {
                        $(this).toggleClass('related-more-button-active');
                        $('.show-related-blogs').slideToggle();

                    });


                    //Before li icons 50-50 module
                    $('.arras-tick-li').prepend('<img src="/wp-content/themes/arras/dist/images/icons/check-icon.png">');


                    //History Slider Sync
                    var sync1 = $("#contentSync");
                    var sync2 = $("#yearSync");
                    var slidesPerPage = 5; //globaly define number of elements per page

                    sync1.owlCarousel({
                        center: true,
                        loop: true,
                        items: 1,
                        slideSpeed: 2000,
                        nav: false,
                        mouseDrag: false,
                        touchDrag: false,
                        autoplay: false,
                        dots: false,
                        responsiveRefreshRate: 200,
                    });

                    sync2.owlCarousel({
                        center: true,
                        loop: true,
                        items: slidesPerPage,
                        responsive: {
                            0: {
                                items: 3
                            },
                            600: {
                                items: 5
                            },
                        },
                        nav: true,
                        navText: [
                            "<img class='owl-left-icon' src='/wp-content/themes/arras/dist/images/icons/circle-arrow-white.png'>",
                            "<img class='owl-right-icon' src='/wp-content/themes/arras/dist/images/icons/circle-arrow-white.png'>"
                        ],
                        dots: false,
                        smartSpeed: 200,
                        slideSpeed: 500,
                        slideBy: 1, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
                        responsiveRefreshRate: 100
                    });

                    //Change Slide when clicking history year
                    sync2.on("click", ".owl-item", function (e) {
                        e.preventDefault();
                        var number = $(this).index();
                        sync1.data('owl.carousel').to(number + 1, 300);
                        sync2.data('owl.carousel').to(number + 1, 300);
                    });

                    //Change Slide on Prev/Next click
                    var owl = $('#contentSync.owl-carousel');
                    owl.owlCarousel();

                    $('#yearSync .owl-prev').click(function () {
                        owl.trigger('prev.owl.carousel');
                    });

                    $('#yearSync .owl-next').click(function () {
                        owl.trigger('next.owl.carousel');
                    });


                    //Restricted Content

                    $('#user_login').attr('placeholder', 'Name');
                    $('#user_email').attr('placeholder', 'Email');
                    $('#user_pass').attr('placeholder', 'Password');


                },
                finalize: function () {
                    // JavaScript to be fired on all pages, after page specific JS is fired

                    //Fixed Navigation

                    var headerHeight = $('.header').outerHeight();
                    var sliderHeight = $('.slider-container').height() - headerHeight - 10;

                    $(window).scroll(function () {

                        var currentScrollTop = $(window).scrollTop();

                        if (currentScrollTop >= headerHeight) {
                            $(".header").addClass("fixed-navigation");
                        } else {
                            $(".header").removeClass("fixed-navigation");
                        }

                        /*   if (('.breadcrumbs-background').length) {
                         if (currentScrollTop >= sliderHeight) {
                         $('.breadcrumbs-background').addClass('fixed-breadcrumbs');
                         $('.breadcrumbs-background').css({
                         'top': headerHeight,
                         });
                         } else {
                         $('.breadcrumbs-background').removeClass('fixed-breadcrumbs');
                         }
                         }*/

                    });


                    //Comment form textarea placeholder

                    $(".comment-form-comment textarea").attr("placeholder", "Enter your comment here...");


                }
            },
// Home page
            'home': {
                init: function () {
                    // JavaScript to be fired on the home page
                }
                ,
                finalize: function () {
                    // JavaScript to be fired on the home page, after the init JS
                }
            }
            ,
// About us page, note the change from about-us to about_us.
            'about_us': {
                init: function () {
                    // JavaScript to be fired on the about us page
                }
            }
        }
        ;

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

// Load Events
    $(document).ready(UTIL.loadEvents);

})
(jQuery); // Fully reference jQuery after this point.
